import React, { useEffect } from 'react'
import Head from 'next/head'
import PropTypes from 'prop-types'
import { install } from 'resize-observer'

import App from '@/components/App'
import '@/styles/globals.scss'
import '@/utils/rollbar'

export default function MyApp({ Component, pageProps }) {
  useEffect(() => {
    const boldText = 'font-weight: bold'
    const normalText = 'font-weight: normal'
    // eslint-disable-next-line no-console
    console.log(`%cWelcome to Neat!
    \n%cRelease Date: %c${process.env.releaseDate}
    \n%cTag: %c${process.env.NEXT_PUBLIC_BUILD_TAG}
    \n%cEnvironment: %c${process.env.NEXT_PUBLIC_ENV}`,
    boldText,
    boldText, normalText,
    boldText, normalText,
    boldText, normalText)
  }, [])

  useEffect(() => {
    // https://neatco.jira.com/browse/NEAT-14191
    if (!window.ResizeObserver) install()
  }, [])

  return (
    <>
      <Head>
        <meta content="width=device-width, initial-scale=1" name="viewport" />
        <title>Neat</title>
        <link href="/favicon.ico" rel="shortcut icon" />
      </Head>
      <App layoutProps={{ ...Component.layoutProps }}>
        <Component {...pageProps} />
      </App>
    </>
  )
}

MyApp.propTypes = {
  Component: PropTypes.func,
  pageProps: PropTypes.shape({}),
}
