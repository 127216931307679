import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import styles from './ContentList.module.scss'

const ContentList = ({ children, className, style }) => (
  <ul className={cx(styles.root, className)} style={style}>
    {children}
  </ul>
)

ContentList.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.objectOf(PropTypes.string),
}

export default ContentList
