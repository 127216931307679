import React from 'react'
import { DialogsRendererPropTypes } from '@theneatcompany/nui'

import { Link, Text, Dialog, Button } from '@/components/common'
import routes from '@/routes'

import styles from './CookieConsentDialog.module.scss'

export default function CookieConsentDialog({ onClose, children, ...rest }) {
  return (
    <Dialog
      onClose={onClose}
      shouldReturnFocusOnClose={false}
      title="Cookie Usage"
      {...rest}
    >
      <Dialog.Content className={styles.content}>
        <Text.P
          center
          messageProps={{
            id: 'cookieConsent.p',
            values: {
              link: <Link messageId="cookieConsent.link" onClick={onClose} size="small" to={routes.privacy} />,
            },
          }}
          size="small"
        />
        <Dialog.Actions>
          <Button messageId="cta.close" onClick={onClose} variant="primary" />
        </Dialog.Actions>
      </Dialog.Content>
    </Dialog>
  )
}

CookieConsentDialog.propTypes = {
  ...DialogsRendererPropTypes,
}
