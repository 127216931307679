import React, { useState } from 'react'
import { useAlerts } from '@theneatcompany/nui'
import PropTypes from 'prop-types'
import { useFormContext } from 'react-hook-form'

import { Card, Text } from '@/components/common'
import Form from '@/components/forms/Form'
import InputField from '@/components/forms/InputField'
import { Column, Row } from '@/components/layout'
import Yup from '@/utils/yup'
import { extractMessage } from '@/utils/errors'
import usePathnameChange from '@/hooks/usePathnameChange'
import { sendError } from '@/utils/rollbar'

const FORM_LINK = 'https://docs.google.com/forms/u/0/d/e/1FAIpQLSek4wf7M0uL_8ndJpjn1YTvZEQcSRCYSdteEfxUPAjWtkxEzQ/formResponse'

const FormResetter = () => {
  const { reset } = useFormContext()
  usePathnameChange(reset)
  return null
}

export default function MailingListOptInForm({ emailOnly = false, buttonMessageId = 'cta.subscribe', buttonVariant = 'secondary' }) {
  const [succeeded, setSucceeded] = useState(false)
  const alerts = useAlerts()
  usePathnameChange(() => succeeded && setSucceeded(false))
  const schema = Yup.object({
    email: Yup.string().trim().lowercase().email()
      .required(),
    ...!emailOnly ? {
      firstName: Yup.string().trim().required(),
      lastName: Yup.string().trim().required(),
    } : {},
  })

  const handleSubmit = async (model) => {
    const formData = new FormData()
    const values = schema.cast(model)
    if (!emailOnly) {
      formData.append('entry.130755717', values.firstName)
      formData.append('entry.923545791', values.lastName)
    }
    formData.append('entry.637530959', values.email)
    formData.append('entry.1484635168', process.env.NEXT_PUBLIC_ENV)

    try {
      await fetch('/api/lead/create', {
        method: 'POST',
        body: JSON.stringify({ ...values, source: 'newsletter' }),
      })
    } catch (error) {
      sendError(error, 'Failed to create lead: Mailing List')
    }

    try {
      await fetch(`${FORM_LINK}`, {
        method: 'POST',
        mode: 'no-cors',
        body: formData,
      })
      setSucceeded(true)
    } catch (err) {
      alerts.error(extractMessage(err))
    }
  }
  return succeeded ? (
    <Card intent="success">
      <Text.P messageId="pages.emailPreferences.subscribe.success" />
    </Card>
  ) : (
    <Form
      initialValues={{ firstName: '', lastName: '', email: '' }}
      onSubmit={handleSubmit}
      options={{ mode: 'all' }}
      schema={schema}
    >
      {!emailOnly && (
        <Row gap={16} mobileBreakpoint="small" noMobileGap>
          <Column>
            <InputField ignoreTouched={false} labelProps={{ hidden: true }} name="firstName" />
          </Column>
          <Column>
            <InputField ignoreTouched={false} labelProps={{ hidden: true }} name="lastName" />
          </Column>
        </Row>)}
      <Row>
        <Column>
          <InputField
            autoComplete="email"
            ignoreTouched={false}
            indicateValid
            labelProps={{ hidden: true }}
            name="email"
          />
        </Column>
      </Row>
      <Form.Action
        messageId={buttonMessageId}
        submittingMessageId="cta.processing"
        type="submit"
        variant={buttonVariant}
      />
      <FormResetter />
    </Form>
  )
}

MailingListOptInForm.propTypes = {
  emailOnly: PropTypes.bool,
  buttonMessageId: PropTypes.string,
  buttonVariant: PropTypes.string,
}
