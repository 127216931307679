import React from 'react'
import PropTypes from 'prop-types'

import { Link } from '@/components/common'
import routes from '@/routes'
import { TRIAL_SKU } from '@/constants'

const TryFreeCta = ({
  buttonMessageId = 'cta.tryNeatFree',
  sku = TRIAL_SKU,
  ...props
}) => (
  <Link
    data-buyinfo='{"sku":"neatyrt","source":"neat.com CC Trial"}'
    messageId={buttonMessageId}
    size="xlarge"
    to={`${routes.signUp}?sku=${sku}`}
    variant="success"
    {...props}
  />
)

TryFreeCta.propTypes = {
  sku: PropTypes.string,
  buttonMessageId: PropTypes.string,
}

export default TryFreeCta
