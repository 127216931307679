import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import isNumber from 'lodash/isNumber'

import { Rows } from '@/components/layout'

import styles from './Section.module.scss'

const Section = ({
  bgBlob,
  bgBrand,
  bgBrandTertiary,
  bgSecondary,
  bgForeground,
  children,
  className,
  gap = 24,
  id,
  wrapperClassName,
  responsive = false,
  paddingVertical = 64,
  paddingHorizontal = 24,
  paddingTop,
  paddingBottom,
  paddingLeft,
  paddingRight,
  ...rest
}) => {
  const backgroundClass = {
    'bgcolor-brand': bgBrand,
    'bgcolor-background-secondary': bgSecondary,
    'bg-blob': bgBlob,
    'bgcolor-brand-tertiary': bgBrandTertiary,
    'bgcolor-foreground': bgForeground,
  }

  const getPaddingProps = () => {
    const values = {
      paddingVertical,
      paddingHorizontal,
      paddingTop,
      paddingBottom,
      paddingLeft,
      paddingRight,
    }
    if (isNumber(paddingTop) || isNumber(paddingBottom)) {
      values.paddingTop = paddingTop ?? paddingVertical
      values.paddingBottom = paddingBottom ?? paddingVertical
      values.paddingVertical = undefined
    }

    if (isNumber(paddingLeft) || isNumber(paddingRight)) {
      values.paddingLeft = paddingLeft ?? paddingHorizontal
      values.paddingRight = paddingRight ?? paddingHorizontal
      values.paddingHorizontal = undefined
    }

    return values
  }

  return (
    <div className={cx(styles.wrapper, backgroundClass, wrapperClassName)} id={id}>
      <Rows
        alignCenter
        className={cx(styles.content, responsive && styles.responsive, className)}
        gap={gap}
        {...getPaddingProps()}
        {...rest}
      >
        {children}
      </Rows>
    </div>
  )
}

Section.propTypes = {
  bgBlob: PropTypes.bool,
  bgBrand: PropTypes.bool,
  bgBrandTertiary: PropTypes.bool,
  bgSecondary: PropTypes.bool,
  bgForeground: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  gap: PropTypes.number,
  id: PropTypes.string,
  wrapperClassName: PropTypes.string,
  /* whether padding should be reduced on mobile devices */
  responsive: PropTypes.bool,
  paddingVertical: PropTypes.number,
  paddingHorizontal: PropTypes.number,
  paddingTop: PropTypes.number,
  paddingBottom: PropTypes.number,
  paddingLeft: PropTypes.number,
  paddingRight: PropTypes.number,
}

export default Section
