import React from 'react'
import PropTypes from 'prop-types'
import { InputField as NuiInputField } from '@theneatcompany/nui'
import { useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'

const InputField = ({
  disabled = false,
  name,
  fieldOptions = {},
  indicateValid = false,
  labelProps = {},
  label: labelProp = labelProps.label,
  placeholder,
  shouldDisplayErrorMessage = true,
  helperMessage,
  ignoreTouched = true,
  intent,
  ...rest
}) => {
  const { register, formState, getFieldState } = useFormContext()
  const { invalid, isDirty, error, isTouched } = getFieldState(name, formState)
  const { formatMessage } = useIntl()

  const { isSubmitting, isSubmitted } = formState
  const errorMessage = error?.message
  const label = labelProp ?? formatMessage({ id: `label.${name}` })
  const hasErrors = ignoreTouched || isSubmitted ? !!errorMessage : isTouched && !!errorMessage

  const getIntent = () => {
    if (intent) return intent
    if (hasErrors) return 'danger'

    return indicateValid && (!invalid && isDirty && isTouched) ? 'success' : undefined
  }

  return (
    <NuiInputField
      name={name}
      {...register(
        name,
        fieldOptions,
      )}
      disabled={disabled || isSubmitting}
      helperMessage={helperMessage || (hasErrors && shouldDisplayErrorMessage ? errorMessage : undefined)}
      intent={getIntent()}
      label={label}
      labelProps={labelProps}
      placeholder={placeholder || label}
      {...rest}
    />
  )
}

InputField.propTypes = {
  disabled: PropTypes.bool,
  fieldOptions: PropTypes.shape({}),
  indicateValid: PropTypes.bool,
  name: PropTypes.string.isRequired,
  label: PropTypes.node,
  labelProps: PropTypes.shape({
    label: PropTypes.node,
  }),
  placeholder: PropTypes.node,
  helperMessage: PropTypes.node,
  shouldDisplayErrorMessage: PropTypes.bool,
  ignoreTouched: PropTypes.bool,
  intent: PropTypes.string,
}

export default InputField
