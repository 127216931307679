import PropTypes from 'prop-types'

// @TODO can add subcomponents if they seem helpful, e.g. Title, Header, Section, etc

const Page = ({ children }) => children

Page.propTypes = {
  children: PropTypes.node,
}

export default Page
