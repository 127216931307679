import React, { useEffect } from 'react'

import { useSignUp } from '@/components/Providers/SignUpProvider'

import styles from './TopBar.module.scss'

export default function TopBar() {
  const { bannerDescription } = useSignUp()

  useEffect(() => {
    document.body.classList.add('topbanner-visible')

    return () => document.body.classList.remove('topbanner-visible')
  }, [])

  return (
    <div className={styles.topBarContainer}>
      <div>🎉 {bannerDescription} 🎉</div>
    </div>
  )
}
