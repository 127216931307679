import React from 'react'
import { useIntl } from 'react-intl'
import Image from 'next/image'

import { Box, Rows } from '@/components/layout'
import { Link } from '@/components/common'
import { capitalizeFirstLetter } from '@/utils/string'
import { URLS } from '@/constants'
import neatWhiteLogoImg from '@/assets/footer/neat-white-logo.png'
import appStoreImg from '@/assets/footer/app-store.png'
import playStoreImg from '@/assets/footer/play-store.png'
import socialFacebookImg from '@/assets/footer/social-facebook.png'
import socialLinkedinImg from '@/assets/footer/social-linkedin.png'
import socialTwitterImg from '@/assets/footer/social-twitter.png'

const socialLinks = [
  { id: 'facebook', width: 8, img: socialFacebookImg },
  { id: 'twitter', width: 19, img: socialTwitterImg },
  { id: 'linkedin', alt: 'LinkedIn', width: 17, img: socialLinkedinImg },
]
export default function FooterExternalLinks() {
  const { formatMessage } = useIntl()

  return (
    <Rows gap={8} style={{ alignItems: 'flex-start' }}>
      <Link to="/">
        <Image
          alt="Neat"
          height="22"
          src={neatWhiteLogoImg}
          width="75"
        />
      </Link>
      <Box gap={16} style={{ marginBottom: '12px' }}>
        {socialLinks.map(({ id, alt, width, img }) => (
          <Link key={id} external openTab to={URLS.social[id]}>
            <Image
              alt={alt || capitalizeFirstLetter(id)}
              height={16}
              src={img}
              width={width}
            />
          </Link>))}
      </Box>
      <Link external title={formatMessage({ id: 'footer.appStore.title' })} to={URLS.appStore}>
        <Image
          alt={formatMessage({ id: 'footer.appStore.alt' })}
          height={39}
          src={appStoreImg}
          width={118}
        />
      </Link>

      <Link external title={formatMessage({ id: 'footer.playStore.title' })} to={URLS.playStore}>
        <Image
          alt={formatMessage({ id: 'footer.playStore.alt' })}
          height={35}
          src={playStoreImg}
          width={118}
        />
      </Link>
      <img
        alt={formatMessage({ id: 'footer.bbbAlt' })}
        height="42px"
        src="https://seal-dc-easternpa.bbb.org/seals/black-seal-200-42-whitetxt-bbb-45556105.png"
        width="200px"
      />
    </Rows>
  )
}
