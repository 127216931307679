/* eslint-disable react/require-default-props */
import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import { Button as NuiButton } from '@theneatcompany/nui'
import { FormattedMessage } from 'react-intl'

const Button = forwardRef(({
  messageProps = {},
  messageId = messageProps.id,
  children,
  variant,
  round = !!variant && variant !== 'default' && variant !== 'link',
  ...rest
}, ref) => (
  <NuiButton ref={ref} round={round} variant={variant} {...rest}>
    {messageId ? <FormattedMessage {...messageProps} id={messageId} /> : children}
  </NuiButton>
))

Button.propTypes = {
  children: PropTypes.node,
  messageId: PropTypes.string,
  messageProps: PropTypes.shape({ id: PropTypes.string }),
  round: PropTypes.bool,
  variant: PropTypes.string,
}

export default Button
