import React from 'react'
import PropTypes from 'prop-types'

import { Columns, Column, Section } from '@/components/layout'
import { Text } from '@/components/common'
import { URLS } from '@/constants'
import { ENV } from '@/config'

import FooterNavList from './FooterNavList'
import FooterExternalLinks from './FooterExternalLinks'
import FooterMailingList from './FooterMailingList'
import styles from './Footer.module.scss'

const productNav = [
  { name: 'trackReceipts' },
  { name: 'transactionStreaming' },
  { name: 'taxPrep' },
  { name: 'integrations' },
  { name: 'security' },
  { name: 'receiptDocumentScanners' },
  { name: 'pricing' },
]

const resourcesNav = [
  { name: 'webinars' },
  { name: 'blog', to: '/blog' }, // @TODO will blog be external?
  { name: 'accountingTerms' },
  { name: 'invoiceTemplate' },
  { name: 'incomeStatementTemplate' },
  { name: 'balanceSheetTemplate' },
  { name: 'accountants' },
  { name: 'support', external: true, to: URLS.support.home },
  { name: 'productReleases' },
]

const companyNav = [
  { name: 'ourHistory' },
  { name: 'careers' },
  { name: 'policies' },
]

export default function Footer({ hideNav }) {
  const colProps = { className: styles.col, size: 20 }
  return (
    <Section
      className={styles.section}
      gap={0}
      paddingHorizontal={24}
      paddingVertical={0}
      wrapperClassName={styles.sectionWrapper}
    >
      <Columns className={`${styles.cols} ${hideNav ? styles.noNav : ''}`} element="footer" gap={0} wrap>
        <Column {...colProps}>
          <FooterExternalLinks />
        </Column>
        {hideNav ||
        <>
          <Column {...colProps}>
            <FooterNavList navItems={productNav} titleMessageId="footer.navLists.product" />
          </Column>
          <Column {...colProps}>
            <FooterNavList navItems={resourcesNav} titleMessageId="footer.navLists.resources" />
          </Column>
          <Column {...colProps}>
            <FooterNavList navItems={companyNav} titleMessageId="footer.navLists.company" />
          </Column>
        </>}
        <Column {...colProps}>
          <FooterMailingList />
        </Column>
      </Columns>
      <footer className={styles.copyrightWrapper}><Text.P center messageId="copyright" onTint size="tiny" suppressHydrationWarning /></footer>
      {ENV !== 'production' && ENV !== 'development' && <Text.P onTint size="tiny">Tag: {process.env.NEXT_PUBLIC_BUILD_TAG}</Text.P>}
    </Section>
  )
}

Footer.propTypes = {
  hideNav: PropTypes.bool,
}
