import React, {
  useContext,
  useMemo,
  useCallback,
  createContext,
  useState,
  useEffect,
  useRef,
} from 'react'
import PropTypes from 'prop-types'
import { useRouter } from 'next/router'
import { LiveChatLoaderProvider, Drift } from 'react-live-chat-loader'

import usePathnameChange from '@/hooks/usePathnameChange'
import usePrevious from '@/hooks/usePrevious'

const DriftContext = createContext(null)

export const useDrift = () => useContext(DriftContext)

const FACADE_CLASSNAME = 'pm-drift-facade-container'

const DriftProvider = ({ children }) => {
  const [isDriftReady, setDriftReady] = useState(false)
  const { pathname } = useRouter()
  const prevPathname = usePrevious(pathname)
  const didClickOnFacade = useRef(false)

  const openChat = useCallback((interactionId, options) => {
    window.drift.api.startInteraction({ interactionId, ...options })
  }, [])

  const loadPlaybook = (options = {}) => {
    let playbookId = 317816
    const { goToConversation } = options

    if (pathname === '/migration-support') {
      playbookId = 362364
    }
    setDriftReady(true)
    openChat(playbookId, { goToConversation: goToConversation ?? didClickOnFacade.current })
  }

  usePathnameChange(() => {
    if (pathname === '/migration-support' || prevPathname === '/migration-support') {
      loadPlaybook({ goToConversation: false })
    }
  })

  const facadeContainerListener = useCallback((event) => {
    if (didClickOnFacade.current) return
    const facadeContainer = document.querySelector(`.${FACADE_CLASSNAME}`)
    if (facadeContainer?.contains(event.target)) {
      didClickOnFacade.current = true
    }
  }, [])

  useEffect(() => {
    document.addEventListener('click', facadeContainerListener)
    return () => document.removeEventListener('click', facadeContainerListener)
  }, [])

  useEffect(() => {
    if (isDriftReady) {
      document.removeEventListener('click', facadeContainerListener)
    }
  }, [isDriftReady])

  const providerValue = useMemo(() => ({
    isDriftReady,
    openChat,
  }), [isDriftReady, openChat])

  return (
    <LiveChatLoaderProvider idlePeriod="500" onReady={loadPlaybook} provider="drift" providerKey="zxs2h5fz37vs">
      <DriftContext.Provider value={providerValue}>
        {children}
      </DriftContext.Provider>
      <Drift color="#515EDA" containerClass={FACADE_CLASSNAME} />
    </LiveChatLoaderProvider>
  )
}

DriftProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default DriftProvider
