import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'

import { Box } from '@/components/layout'

import styles from './ButtonGroup.module.scss'

const ButtonGroup = ({ children, className, gap = 16, justifyCenter, withCustomBreakpoint = false, ...rest }) => {
  const getJustifyCenterValue = () => {
    if (rest.justifyStart || rest.center || rest.justifyEnd) return false
    return typeof justifyCenter === 'boolean' ? justifyCenter : true
  }

  return (
    <Box
      className={cx(!withCustomBreakpoint && styles.default, className)}
      gap={gap}
      justifyCenter={getJustifyCenterValue()}
      {...rest}
    >
      {children}
    </Box>)
}

ButtonGroup.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  gap: PropTypes.number,
  justifyCenter: PropTypes.bool,
  /* breakpoint should be applied using className prop */
  withCustomBreakpoint: PropTypes.bool,
}

export default ButtonGroup
