import React, { useState } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { useIntl } from 'react-intl'
import { userAgent } from '@theneatcompany/nui'

import { Link, Menu, MenuItem, Popover, DropdownButton, NavLink } from '@/components/common'
import routes from '@/routes'
import { isTouchDevice } from '@/utils/device'

import styles from './TopNavButton.module.scss'

const TopNavButton = ({ to, name, menu, ...rest }) => {
  const menuStyle = userAgent.isMobile || isTouchDevice() ? 'mobile' : 'desktop'

  const { formatMessage } = useIntl()
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const handlePopoverInteraction = willBeOpen => setIsMenuOpen(willBeOpen)
  const renderPopoverContent = () => (
    <Menu className={cx(styles.menu, 'shadow-card')}>
      {menu.map((item) => (
        <MenuItem
          key={item.name}
          className={styles.submenulink}
          LinkComponent={NavLink}
          text={formatMessage({ id: `navLinks.${item.name}` })}
          to={item.to || routes[item.name]}
        />
      ))}
    </Menu>
  )

  return (
    menu ? (
      <Popover
        content={renderPopoverContent()}
        interactionKind={menuStyle === 'desktop' ? 'hover' : 'click'}
        isOpen={isMenuOpen}
        minimal
        modifiers={{
          offset: { enabled: true, options: { offset: [0, 10] } },
        }}
        onInteraction={handlePopoverInteraction}
        placement="bottom"
      >
        <DropdownButton key={name} active={isMenuOpen} className={styles.dropdownButton} variant="default">
          {formatMessage({ id: `navLinks.${name}` })}
        </DropdownButton>
      </Popover>
    ) : (
      <Link
        key={name}
        className={styles.menulink}
        to={to || routes[name]}
        {...rest}
      >
        {formatMessage({ id: `navLinks.${name}` })}
      </Link>
    )
  )
}

TopNavButton.propTypes = {
  to: PropTypes.string,
  menu: PropTypes.arrayOf(PropTypes.shape({})),
  name: PropTypes.string,
}

export default TopNavButton
