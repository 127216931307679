/* eslint-disable react/require-default-props */
import React, { forwardRef, useId } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

const Checkbox = forwardRef(({
  label = '',
  preventBubbling = true,
  onClick,
  size = 'medium',
  status = 'default',
  disabled = false,
  checkboxBufferAreaClassName = '',
  testId = '',
  ...rest
}, ref) => {
  const id = useId()

  const handleClick = (e) => {
    if (preventBubbling) e.stopPropagation()
    onClick?.(e)
  }

  const handleLabelClick = (e) => {
    if (preventBubbling) e.stopPropagation()
  }

  const baseClass = 'nui-checkbox'
  const sizeClass = `${baseClass}--${size}`
  const errorClass = `${baseClass}--${status}`

  return (
    <div className={cx(baseClass, sizeClass, errorClass)}>
      <input
        ref={ref}
        className="nui-checkbox_input"
        data-testid={(testId || label) || null}
        disabled={disabled}
        id={id}
        onClick={handleClick}
        type="checkbox"
        {...rest}
      />
      {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
      <label className="nui-label nui-checkbox_label" htmlFor={id} onClick={handleLabelClick}>
        <span className={cx('nui-label-text', { 'nui-accessibly-hidden': !label })}>{label}</span>
        {checkboxBufferAreaClassName !== '' && <div className={checkboxBufferAreaClassName} />}
      </label>
    </div>
  )
})

Checkbox.propTypes = {
  label: PropTypes.node,
  preventBubbling: PropTypes.bool,
  onClick: PropTypes.func,
  size: PropTypes.string,
  status: PropTypes.string,
  disabled: PropTypes.bool,
  checkboxBufferAreaClassName: PropTypes.string,
  testId: PropTypes.string,
}

export default Checkbox
