import Rollbar from 'rollbar'

import { ENV } from '@/config'

const ROLLBAR_TOKEN = typeof window === 'undefined' ? process.env.ROLLBAR_SERVER_TOKEN : process.env.NEXT_PUBLIC_ROLLBAR_CLIENT_TOKEN
// For cases where Rollbar needs to be executed
// on development environment, set ROLLBAR_ENABLED to true or type "rollbar.configure({ enabled: true })"
// in your browser console.
const ROLLBAR_ENABLED = !['development', 'test'].includes(ENV)

const resizeObserverLoopLimitRegEx = /ResizeObserver loop limit exceeded/gi

const config = {
  enabled: true,
  accessToken: ROLLBAR_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: true,
  hostSafeList: ['neat.com'],
  checkIgnore: (isUncaught, args) => {
    const [message, err] = args
    const messages = [message, err?.message, err?.stack]
    const shouldIgnore = !!(messages.some(v => resizeObserverLoopLimitRegEx.test(v)) && isUncaught)
    return shouldIgnore
  },
  payload: {
    environment: ENV,
    appBuild: process.env.NEXT_PUBLIC_BUILD_TAG,
    client: {
      javascript: {
        source_map_enabled: true,
        guess_uncaught_frames: true,
        code_version: process.env.NEXT_PUBLIC_BUILD_TAG,
      },
    },
  },
}

const Instance = Rollbar.init(config)

export const sendError = (error, message) => {
  if (!ROLLBAR_ENABLED) return
  Instance.error(message || error.message, error)
}

export const sendWarning = (error, message) => {
  if (!ROLLBAR_ENABLED) return
  Instance.warning(message || error.message, error)
}

export const trackSignUpPerson = (data) => {
  if (!data || !ROLLBAR_ENABLED) return

  Instance.info('Sign Up Started', {
    person: {
      ...(data.neatUserId ? { id: data.neatUserId } : {}),
      email: data.email,
      username: `${data.firstName} ${data.lastName}`,
    },
  })
}

export default Instance
