import React, { useState, Fragment, useEffect, useRef } from 'react'
import { useIntl } from 'react-intl'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { useRouter } from 'next/router'

import { Button, Popover, Menu, MenuItem, Link, Collapse, NavLink } from '@/components/common'
import useBreakpoint from '@/hooks/useBreakpoint'
import routes from '@/routes'
import { WEB_APP_URL } from '@/config'
import { MOBILE_BREAKPOINT, TRIAL_SKU } from '@/constants'

import styles from './MobileHeaderNav.module.scss'

const hasSubMenu = item => Array.isArray(item?.menu)

const MobileHeaderNav = ({ items }) => {
  const { formatMessage } = useIntl()
  const [expandedSections, setExpandedSections] = useState({})
  const { pathname } = useRouter()
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)
  const [collapseOpen, setCollapseOpen] = useState(false)
  const isDesktopWidth = useBreakpoint(MOBILE_BREAKPOINT)
  const didCloseFromResize = useRef(false)

  useEffect(() => {
    if (isDesktopWidth && isPopoverOpen) {
      setIsPopoverOpen(false)
      didCloseFromResize.current = true
    } else if (!isDesktopWidth && didCloseFromResize.current && !isPopoverOpen) {
      setIsPopoverOpen(true)
    }
  }, [isDesktopWidth, isPopoverOpen])

  const getActiveClass = (item) => {
    const isActive = hasSubMenu(item)
      ? item.menu.some(subItem => pathname === routes[subItem.name])
      : pathname === routes[item.name]
    return isActive && styles.active
  }

  const handlePopoverInteraction = (willBeOpen) => {
    if (!willBeOpen) {
      setCollapseOpen(false)
      setTimeout(() => {
        setIsPopoverOpen(false)
      }, 200)
    } else {
      setIsPopoverOpen(true)
    }
  }
  const renderMenuItem = (item) => {
    const isExpandable = hasSubMenu(item)
    const isExpanded = isExpandable && expandedSections[item.name]
    return (
      <Fragment key={item.name}>
        <MenuItem
          key={item.name}
          className={cx(styles.menuitem, getActiveClass(item))}
          LinkComponent={NavLink}
          onClick={isExpandable ?
            () => setExpandedSections(prev => ({ ...prev, [item.name]: !prev[item.name] }))
            : undefined}
          rightIconProps={isExpandable ?
            { name: 'chevron-down', title: isExpanded ? 'Collapse' : 'Expand' }
            : undefined}
          shouldDismissPopover={!isExpandable}
          text={formatMessage({ id: `navLinks.${item.name}` })}
          to={item.to || routes[item.name]}
        />
        {isExpandable &&
        <Collapse isOpen={isExpanded}>
          {item.menu.map((subItem) => <MenuItem
            key={subItem.name}
            className={cx(styles.subMenuItem, getActiveClass(subItem))}
            LinkComponent={NavLink}
            text={formatMessage({ id: `navLinks.${subItem.name}` })}
            to={subItem.to || routes[subItem.name]}
          />)}
        </Collapse>}
      </Fragment>
    )
  }
  const renderPopoverContent = () => (
    <Collapse isOpen={collapseOpen}>
      <Menu className={styles.menu}>
        {items.map((item) => renderMenuItem(item))}
        <MenuItem className={styles.menuitem} href={WEB_APP_URL} text={formatMessage({ id: 'cta.signIn' })} />
        <MenuItem
          className={styles.menuitem}
          data-buyinfo='{"sku":"neatyrt","source":"neat.com CC Trial"}'
          LinkComponent={Link}
          text={formatMessage({ id: 'cta.tryNeatFREE' })}
          to={`${routes.signUp}?sku=${TRIAL_SKU}`}
        />
      </Menu>
    </Collapse>
  )

  return (
    <Popover
      content={renderPopoverContent()}
      isOpen={isPopoverOpen}
      minimal
      modifiers={{
        offset: { enabled: true, options: { offset: [0, 10] } },
      }}
      onInteraction={handlePopoverInteraction}
      onOpened={() => {
        setCollapseOpen(true)
        if (didCloseFromResize.current) didCloseFromResize.current = false
      }}
      placement="bottom-start"
      popoverClassName={styles.popover}
      portalClassName={styles.portal}
      shouldCloseWhenReferenceIsHidden={false}
      transitionDuration={0}
    >
      <Button iconOnly iconProps={{ name: 'hamburger', title: 'menu', fill: 'dark-gray' }} variant="default" />
    </Popover>
  )
}

MobileHeaderNav.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    to: PropTypes.string,
    menu: PropTypes.arrayOf(PropTypes.shape({})),
    name: PropTypes.string.isRequired,
  })).isRequired,
}

export default MobileHeaderNav
