/* eslint-disable react/require-default-props */
import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'

import { Box, BoxTypes } from '@/components/layout'

const Column = forwardRef(({
  children,
  fill = true,
  gap = 16,
  size,
  ...rest
}, ref) => (
  <Box
    ref={ref}
    colSize={size}
    fill={fill}
    gap={gap}
    type={BoxTypes.COLUMN}
    {...rest}
  >
    {children}
  </Box>
))

Column.propTypes = {
  children: PropTypes.node,
  fill: PropTypes.bool,
  gap: PropTypes.number,
  size: PropTypes.number,
}

export default Column
