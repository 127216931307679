import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import styles from './IconList.module.scss'

const IconListItemPropTypes = {
  className: PropTypes.string,
  src: PropTypes.string,
  text: PropTypes.node,
  id: PropTypes.string.isRequired,
}

// using backgroundImage method since that's what was on neat.com
const IconListItem = ({ text, src, className, id, ...rest }) => (
  <li
    className={cx(styles.li, className)}
    style={src ? { backgroundImage: `url(${src})` } : undefined}
    {...rest}
  >
    {text}
  </li>
)

IconListItem.propTypes = IconListItemPropTypes

const IconList = ({ className, items, small = false, circleIcon = false, ...rest }) => (
  <ul className={cx(className, styles.root, small && styles.small, circleIcon && styles.circleIcon)} {...rest}>
    {items.map((item) => <IconListItem key={item.id} {...item} />)}
  </ul>
)

IconList.propTypes = {
  circleIcon: PropTypes.bool,
  className: PropTypes.string,
  itemClassName: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape(IconListItemPropTypes)),
  small: PropTypes.bool,
}

export default IconList
