import { useEffect } from 'react'
import { useRouter } from 'next/router'

import usePrevious from '@/hooks/usePrevious'

export default function usePathnameChange(onChange) {
  const { pathname } = useRouter()
  const prevPathname = usePrevious(pathname)

  useEffect(() => {
    if (prevPathname && prevPathname !== pathname) {
      onChange()
    }
  }, [pathname])
}
