import React, { useEffect, useState } from 'react'
import { Button } from '@theneatcompany/nui'
import cx from 'classnames'

import styles from './ScrollToTopButton.module.scss'

export default function ScrollToTopButton() {
  const [isVisible, setVisible] = useState(false)
  const scrollToTop = () => window.scrollTo({ top: 0, behavior: 'smooth' })

  useEffect(() => {
    const handleScroll = () => {
      setVisible(window.scrollY > 200)
    }

    window.addEventListener('scroll', handleScroll)

    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  return (
    <Button
      className={cx(styles.scrollToTopBtn, isVisible && styles.isActive)}
      iconOnly
      iconProps={{
        name: 'chevron-up',
        title: 'scroll to top',
      }}
      onClick={scrollToTop}
      size="large"
      variant="primary"
    />
  )
}
