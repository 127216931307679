import React from 'react'
import PropTypes from 'prop-types'

import { Rows } from '@/components/layout'
import { Link, Text, NavLink } from '@/components/common'
import routes from '@/routes'

import styles from './Footer.module.scss'

export default function FooterNavList({ navItems, titleMessageId }) {
  return (
    <Rows gap={8}>
      <Text.H4
        bold
        className={styles.listHeader}
        messageId={titleMessageId}
        onTint
        size="small"
        type="body"
      />
      {navItems.map((item) => {
        const LinkComponent = item.external ? Link : NavLink
        return (
          <LinkComponent
            key={item.name}
            className={styles.navLink}
            external={item.external}
            messageId={`navLinks.${item.name}`}
            size="xsmall"
            to={item.to || routes[item.name]}
          />
        ) })}
    </Rows>
  )
}

FooterNavList.propTypes = {
  navItems: PropTypes.arrayOf(PropTypes.shape({})),
  titleMessageId: PropTypes.string,
}
