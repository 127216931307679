import * as Yup from 'yup'

import validations, { customMethods } from '@/translations/validations'
import { EMAIL_REGEX } from '@/constants'

function hasLetterChars(message = customMethods.hasLetterChars) {
  return this.test('hasLetterChars', message, value => /[A-Z]|[a-z]/.test(value))
}

function hasDigits(message = customMethods.hasDigits) {
  return this.test('hasDigits', message, value => /.*[0-9].*/.test(value))
}

function disallowSpaces(message = customMethods.disallowSpaces) {
  return this.test('disallowSpaces', message, value => !/\s+/g.test(value))
}

function phoneNumber(options = {}) {
  const {
    path = 'phoneNumber',
    minDigits = 10,
    maxDigits = 10,
    maxLength = 14,
  } = options

  return this.test('phoneNumber', function (value) {
    if (!value) return true

    const hasLetters = /[A-Za-z]/.test(value)

    if (hasLetters) {
      return this.createError({ message: 'cannot contain letters', path })
    }

    const digitsOnly = value.replace(/\D/g, '')

    if (digitsOnly.length < minDigits) {
      return this.createError({ message: `must contain at least ${minDigits} digits`, path })
    }

    if (digitsOnly.length > maxDigits) {
      return this.createError({ message: `must contain at most ${maxDigits} digits`, path })
    }

    if (value.length > maxLength) {
      return this.createError({ message: `must contain at most ${maxLength} characters`, path })
    }

    return true
  })
}

function email(message) {
  return this.matches(EMAIL_REGEX, {
    message: message || validations.string.email,
    name: 'email',
    excludeEmptyString: true,
  })
}

Yup.setLocale(validations)

Yup.addMethod(Yup.string, 'phoneNumber', phoneNumber)
Yup.addMethod(Yup.string, 'hasDigits', hasDigits)
Yup.addMethod(Yup.string, 'disallowSpaces', disallowSpaces)
Yup.addMethod(Yup.string, 'hasLetterChars', hasLetterChars)
Yup.addMethod(Yup.string, 'email', email)

export default Yup
