/* eslint-disable no-template-curly-in-string */
const mixed = {
  default: 'This field is invalid.',
  required: 'This is a required field.',
}

const string = {
  email: 'Please enter a valid email address!',
  max: 'This field must be at most ${max} characters.',
}

export const customMethods = {
  disallowSpaces: 'This field must not contain spaces',
  hasDigits: 'This field should contain at least one digit',
  hasLetterChars: 'This field should contain at least 1 letter',
}

export default {
  mixed,
  string,
}
