import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { AlertsProvider, DialogsProvider } from '@theneatcompany/nui'
import { IntlProvider } from 'react-intl'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

import messages from '@/translations/messages'
import flattenMessages from '@/utils/intl'
import DriftProvider from '@/components/Providers/DriftProvider'
import SignUpProvider from '@/components/Providers/SignUpProvider'
import { GOOGLE_SIGN_IN_ID } from '@/config'

const DEFAULT_LOCALE = 'en-US'

export default function Providers({ children }) {
  const queryClient = useMemo(() => new QueryClient(), [])

  return (
    <IntlProvider
      formats={{
        number: {
          usd: { style: 'currency', currency: 'USD' },
        },
      }}
      locale={DEFAULT_LOCALE}
      messages={flattenMessages(messages[DEFAULT_LOCALE])}
    >
      <QueryClientProvider client={queryClient}>
        <AlertsProvider>
          <DialogsProvider>
            <DriftProvider>
              <SignUpProvider>
                <GoogleOAuthProvider clientId={GOOGLE_SIGN_IN_ID}>
                  {children}
                </GoogleOAuthProvider>
              </SignUpProvider>
            </DriftProvider>
          </DialogsProvider>
        </AlertsProvider>
      </QueryClientProvider>
    </IntlProvider>
  )
}

Providers.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
  ]).isRequired,
}
