/* eslint-disable react/require-default-props */
import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import styles from './Box.module.scss'

export const Types = {
  COLUMN: 'column',
  COLUMNS: 'columns',
  ROW: 'row',
  ROWS: 'rows',
  SECTION: 'section',
}

// @TODO Create a general utility for applying these modifier classes.
const Box = forwardRef(({
  bgBrand,
  bgPrimary,
  className,
  children,
  colSize,
  element,
  gap,
  wrap = false,
  type,
  fill = false,
  alignCenter = false,
  justifyCenter = false,
  center = false,
  textCenter = false,
  mobileBreakpoint = 'medium',
  justifyStart = false,
  maxWidth,
  noMobileGap = false,
  shrink = false,
  paddingTop,
  paddingBottom,
  paddingHorizontal,
  paddingVertical,
  paddingLeft,
  paddingRight,
  marginTop,
  marginBottom,
  marginLeft,
  marginRight,
  marginHorizontal,
  marginVertical,
  pGap,
  ...rest
}, ref) => {
  const baseClass = styles[type] ?? styles.base

  const modifierClasses = {
    [styles[`col-${colSize}`]]: colSize,
    [styles[`gap-${gap}`]]: gap,
    [styles[`pGap-${pGap}`]]: pGap,
    [styles.wrap]: wrap,
    [styles.fill]: fill,
    [styles.center]: center,
    [styles['align-center']]: !center && alignCenter,
    [styles['justify-center']]: !center && justifyCenter,
    'text-center': textCenter,
    [styles['bp-mobile-small']]: mobileBreakpoint === 'small',
    [styles['justify-start']]: justifyStart,
    [styles[`max-w-${maxWidth}`]]: maxWidth,
    [styles.noMobileGap]: noMobileGap,
    [styles.shrink]: shrink,
    [`pb-${paddingBottom}`]: typeof paddingBottom === 'number',
    [`pt-${paddingTop}`]: typeof paddingTop === 'number',
    [`pl-${paddingLeft}`]: typeof paddingLeft === 'number',
    [`pr-${paddingRight}`]: typeof paddingRight === 'number',
    [`py-${paddingVertical}`]: typeof paddingVertical === 'number',
    [`px-${paddingHorizontal}`]: typeof paddingHorizontal === 'number',
    [`mb-${marginBottom}`]: typeof marginBottom === 'number',
    [`mt-${marginTop}`]: typeof marginTop === 'number',
    [`ml-${marginLeft}`]: typeof marginLeft === 'number',
    [`mr-${marginRight}`]: typeof marginRight === 'number',
    [`my-${marginVertical}`]: typeof marginVertical === 'number',
    [`mx-${marginHorizontal}`]: typeof marginHorizontal === 'number',
  }

  const backgroundClasses = {
    'bgcolor-brand': bgBrand,
    'bgcolor-background': bgPrimary,
  }

  const Element = element || 'div'

  return (
    <Element ref={ref} className={cx(baseClass, modifierClasses, backgroundClasses, className)} {...rest}>{children}
    </Element>
  )
})

Box.propTypes = {
  alignCenter: PropTypes.bool,
  bgBrand: PropTypes.bool,
  bgPrimary: PropTypes.bool,
  center: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  colSize: PropTypes.number,
  element: PropTypes.elementType,
  fill: PropTypes.bool,
  gap: PropTypes.number,
  pGap: PropTypes.number,
  justifyCenter: PropTypes.bool,
  justifyStart: PropTypes.bool,
  maxWidth: PropTypes.number,
  mobileBreakpoint: PropTypes.string,
  noMobileGap: PropTypes.bool,
  textCenter: PropTypes.bool,
  type: PropTypes.oneOf(Object.values(Types)),
  wrap: PropTypes.bool,
  shrink: PropTypes.bool,
  paddingTop: PropTypes.number,
  paddingBottom: PropTypes.number,
  paddingLeft: PropTypes.number,
  paddingRight: PropTypes.number,
  paddingHorizontal: PropTypes.number,
  paddingVertical: PropTypes.number,
  marginTop: PropTypes.number,
  marginBottom: PropTypes.number,
  marginLeft: PropTypes.number,
  marginRight: PropTypes.number,
  marginHorizontal: PropTypes.number,
  marginVertical: PropTypes.number,
}

export default Box
