import React from 'react'
import Head from 'next/head'
import PropTypes from 'prop-types'
import { DefaultSeo, NextSeo, WebPageJsonLd, BreadcrumbJsonLd, ArticleJsonLd, CollectionPageJsonLd } from 'next-seo'

import seo from '@/seo'

const WebSiteJsonLd = ({ ...website }) => {
  const data = {
    // this context value is added by default for the standard JsonLd components provided by next-seo
    '@context': 'https://schema.org',
    '@type': 'WebSite',
    '@id': website.id,
    url: website.url,
    name: website.name,
    description: website.description,
    publisher: website.publisher,
    potentialAction: website.potentialAction,
    inLanguage: website.inLanguage,
  }
  return (
    <Head>
      <script dangerouslySetInnerHTML={{ __html: JSON.stringify(data) }} type="application/ld+json" />
    </Head>
  )
}

const PersonJsonLd = ({ ...person }) => {
  const data = {
    '@type': 'Person',
    '@id': person.id,
    name: person.name,
    image: person.image,
  }
  return (
    <Head>
      <script dangerouslySetInnerHTML={{ __html: JSON.stringify(data) }} type="application/ld+json" />
    </Head>
  )
}

const JsonLDParts = ({ seoDetails = {} }) => (
  <>
    <WebSiteJsonLd {...seo.default.jsonLD.website} />
    {seoDetails.jsonLD?.webPage && <WebPageJsonLd {...seoDetails.jsonLD?.webPage} />}
    {seoDetails.jsonLD?.breadCrumb && <BreadcrumbJsonLd {...seoDetails.jsonLD?.breadCrumb} />}
    {seoDetails.jsonLD?.article && <ArticleJsonLd {...seoDetails.jsonLD?.article} />}
    {seoDetails.jsonLD?.collectionPage && <CollectionPageJsonLd {...seoDetails.jsonLD?.collectionPage} />}
    {seoDetails.jsonLD?.person && <PersonJsonLd {...seoDetails.jsonLD?.person} />}
  </>
)

export const SeoBlog = ({ seoDetails }) => (
  <>
    <DefaultSeo {...seo.default} />
    <NextSeo {...seoDetails} />
    <JsonLDParts seoDetails={seoDetails} />
  </>
)

const Seo = ({ pageId, shouldRobotsIndex }) => {
  const noPageId = pageId === 'notFound'
  const robotsContent = shouldRobotsIndex ? {} : { noindex: true, nofollow: true }
  const notFound = noPageId ? { noindex: true, nofollow: false } : {}

  return (
    <>
      <DefaultSeo {...seo.default} />
      <NextSeo
        {...seo[pageId]}
        {...robotsContent}
        {...notFound}
        robotsProps={shouldRobotsIndex && !noPageId ? {
          maxImagePreview: 'large',
          maxSnippet: -1,
          maxVideoPreview: -1,
        } : {}}
      />
      <JsonLDParts seoDetails={seo[pageId]} />
    </>
  )
}

Seo.propTypes = {
  pageId: PropTypes.string,
  shouldRobotsIndex: PropTypes.bool,
}

SeoBlog.propTypes = {
  seoDetails: PropTypes.shape({
    jsonLD: PropTypes.shape({
      webPage: PropTypes.shape({}),
      breadCrumb: PropTypes.shape({}),
      article: PropTypes.shape({}),
      collectionPage: PropTypes.shape({}),
      person: PropTypes.shape({}),
    }),
  }),
}

JsonLDParts.propTypes = {
  seoDetails: PropTypes.shape({}),
}

export default Seo
