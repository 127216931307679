import React from 'react'
import PropTypes from 'prop-types'
import { Text as NuiText } from '@theneatcompany/nui'
import { FormattedMessage } from 'react-intl'
import cx from 'classnames'

import styles from './Text.module.scss'

// @TODO Will need to use forwardRef if the nui Text component is updated to use forwardRef

const Text = ({
  messageProps = {},
  messageId = messageProps.id,
  children,
  className,
  bold,
  normal,
  center,
  onTint,
  color,
  brandPrimary,
  brandSecondary,
  secondary,
  element,
  noMargin,
  responsive = true,
  ...rest
}) => {
  const modifierClasses = {
    [styles.bold]: bold,
    [styles.normal]: normal,
    'text-center': center,
    [styles.noMargin]: noMargin,
    [styles.responsive]: responsive,
  }

  const getColor = () => {
    if (onTint) return 'foreground-on-tint'
    if (brandPrimary) return 'tint-brand-primary'
    if (brandSecondary) return 'tint-brand-secondary'
    if (secondary) return 'foreground-secondary'
    return color
  }

  const getMessageProps = () => {
    const { values, ...restMessageProps } = messageProps
    return {
      values: {
        strong: chunks => <strong>{chunks}</strong>,
        br: <br />,
        ...values,
      },
      ...restMessageProps,
    }
  }

  return (
    <NuiText className={cx(styles.root, modifierClasses, className)} color={getColor()} element={element} {...rest}>
      {messageId ? <FormattedMessage {...getMessageProps()} id={messageId} /> : children}
    </NuiText>
  ) }

const H1 = props => <Text element="h1" size="large" type="display" {...props} />
const H2 = props => <Text element="h2" type="display" {...props} />
const H3 = props => <Text element="h3" size="small" type="display" {...props} />
const H4 = props => <Text element="h4" size="small" type="display" {...props} />
const H5 = props => <Text element="h5" size="small" type="display" {...props} />
const P = props => <Text color="foreground-secondary" element="p" {...props} />

// @TODO potentially add Span component or change the default color to foreground-secondary for span if it makes things easier

Text.H1 = H1
Text.H2 = H2
Text.H3 = H3
Text.H4 = H4
Text.H5 = H5
Text.P = P

Text.propTypes = {
  brandPrimary: PropTypes.bool,
  brandSecondary: PropTypes.bool,
  center: PropTypes.bool,
  bold: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  color: PropTypes.string,
  element: PropTypes.string,
  messageProps: PropTypes.shape({ id: PropTypes.string }),
  messageId: PropTypes.string,
  noMargin: PropTypes.bool,
  normal: PropTypes.bool,
  onTint: PropTypes.bool,
  secondary: PropTypes.bool,
  responsive: PropTypes.bool,
}

export default Text
