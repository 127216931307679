import { useEffect, useState } from 'react'

export default function useBreakpoint(breakpoint) {
  const [value, setValue] = useState(false)

  useEffect(() => {
    const fn = () => setValue(window.innerWidth >= breakpoint)
    fn()
    window.addEventListener('resize', fn)

    return () => window.removeEventListener('resize', fn)
  }, [breakpoint])

  return value
}
