/* eslint-disable react/require-default-props */
import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'

import { Box, BoxTypes } from '@/components/layout'

const Rows = forwardRef(({ children, gap = 16, ...rest }, ref) => (
  <Box ref={ref} gap={gap} type={BoxTypes.ROWS} {...rest}>{children}</Box>
))

Rows.propTypes = {
  children: PropTypes.node,
  gap: PropTypes.number,
}

export default Rows
