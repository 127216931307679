import React, { useEffect } from 'react'
import { Sprite, userAgent } from '@theneatcompany/nui'
import Script from 'next/script'
import PropTypes from 'prop-types'
import { useRouter } from 'next/router'

import { isTouchDevice } from '@/utils/device'
import { BUY_FLOW_GTM_ID, GTM_ID } from '@/config'
import Providers from '@/components/Providers/Providers'
import routes from '@/routes'
import styles from '@/components/layout/Layout.module.scss'

import { Layout } from './layout'

const App = ({ children, layoutProps }) => {
  const { pathname } = useRouter()

  const isInBuyFlow = [routes.signUp, routes.signUpSuccess].includes(pathname)

  useEffect(() => {
    // @TODO Tablet?
    const body = document.querySelector('body')
    if (!body) return

    if (userAgent.isMobile) {
      body.classList.add('mobile-device')
    }
    if (isTouchDevice()) {
      body.classList.add('touch-device')
    }
  }, [])

  return (
    <>
      <div className={styles.app} id="app">
        <Providers>
          <Layout {...layoutProps}>
            {children}
          </Layout>
        </Providers>
      </div>
      {/* Sprite is necessary in order to render the svgs for nui icons */}
      <Sprite />
      <Script id="google-tag-manager" strategy="afterInteractive">
        {`
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','${GTM_ID}');
        `}
      </Script>
      <Script id="microsoft-ads-uet" strategy="afterInteractive">
        {`
          (function(w, d, t, r, u) {
              var f, n, i;
              w[u] = w[u] || [], f = function() {
                  var o = {
                      ti: "26033977",
                      enableAutoSpaTracking: true
                  };
                  o.q = w[u], w[u] = new UET(o), w[u].push("pageLoad")
              }, n = d.createElement(t), n.src = r, n.async = 1, n.onload = n.onreadystatechange = function() {
                  var s = this.readyState;
                  s && s !== "loaded" && s !== "complete" || (f(), n.onload = n.onreadystatechange = null)
              }, i = d.getElementsByTagName(t)[0], i.parentNode.insertBefore(n, i)
          })(window, document, "script", "//bat.bing.com/bat.js", "uetq");
        `}
      </Script>
      {process.env.NEXT_PUBLIC_FULL_STORY_ENABLED === 'true' && (
        <Script id="full-story">
          {`window['_fs_host'] = 'cx.neat.com';
        window['_fs_script'] = 'cx.neat.com/s/fs.js';
        window['_fs_app_host'] = 'app.fullstory.com';
        window['_fs_org'] = 'FXFB7';
        window['_fs_namespace'] = 'FS';
        (function(m,n,e,t,l,o,g,y){
            if (e in m) {if(m.console && m.console.log) { m.console.log('FullStory namespace conflict. Please set window["_fs_namespace"].');} return;}
            g=m[e]=function(a,b,s){g.q?g.q.push([a,b,s]):g._api(a,b,s);};g.q=[];
            o=n.createElement(t);o.async=1;o.crossOrigin='anonymous';o.src='https://'+_fs_script;
            y=n.getElementsByTagName(t)[0];y.parentNode.insertBefore(o,y);
            g.identify=function(i,v,s){g(l,{uid:i},s);if(v)g(l,v,s)};g.setUserVars=function(v,s){g(l,v,s)};g.event=function(i,v,s){g('event',{n:i,p:v},s)};
            g.anonymize=function(){g.identify(!!0)};
            g.shutdown=function(){g("rec",!1)};g.restart=function(){g("rec",!0)};
            g.log = function(a,b){g("log",[a,b])};
            g.consent=function(a){g("consent",!arguments.length||a)};
            g.identifyAccount=function(i,v){o='account';v=v||{};v.acctId=i;g(o,v)};
            g.clearUserCookie=function(){};
            g.setVars=function(n, p){g('setVars',[n,p]);};
            g._w={};y='XMLHttpRequest';g._w[y]=m[y];y='fetch';g._w[y]=m[y];
            if(m[y])m[y]=function(){return g._w[y].apply(this,arguments)};
            g._v="1.3.0";
        })(window,document,window['_fs_namespace'],'script','user');`}
        </Script>
      )}
      {isInBuyFlow && (
        <>
          <Script id={BUY_FLOW_GTM_ID} strategy="afterInteractive">
            {`
              (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','${BUY_FLOW_GTM_ID}');
            `}
          </Script>
          <Script id="facebook-pixel" strategy="afterInteractive">
            {`
              !function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?
              n.callMethod.apply(n,arguments):n.queue.push(arguments)};
              if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
              n.queue=[];t=b.createElement(e);t.async=!0;
              t.src=v;s=b.getElementsByTagName(e)[0];
              s.parentNode.insertBefore(t,s)}(window, document,'script',
              'https://connect.facebook.net/en_US/fbevents.js');
              fbq('init', '213623165805968');
              fbq('track', 'PageView');
            `}
          </Script>
        </>
      )}
    </>
  )
}

App.propTypes = {
  children: PropTypes.node.isRequired,
  layoutProps: PropTypes.shape({}),
}

export default App
