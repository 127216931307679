import React from 'react'
import { Dialog as NuiDialog } from '@theneatcompany/nui'
import cx from 'classnames'
import PropTypes from 'prop-types'

import styles from './Dialog.module.scss'

export default function Dialog({ portalClassName, ...rest }) {
  return (<NuiDialog portalClassName={cx(styles.portal, portalClassName)} {...rest} />)
}

Dialog.Content = NuiDialog.Content
Dialog.Actions = NuiDialog.Actions
Dialog.Footer = NuiDialog.Footer

Dialog.propTypes = {
  portalClassName: PropTypes.string,
}
