import React from 'react'
import PropTypes from 'prop-types'

import { Box, BoxTypes } from '@/components/layout'

const Row = ({ children, gap = 24, ...rest }) => (
  <Box gap={gap} type={BoxTypes.ROW} {...rest}>{children}</Box>
)

Row.propTypes = {
  children: PropTypes.node,
  gap: PropTypes.number,
}

export default Row
