/* eslint-disable react/require-default-props */
import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { useRouter } from 'next/router'

import { Link } from '@/components/common'

const NavLink = forwardRef(({ className, exact, to, ...rest }, ref) => {
  const { pathname } = useRouter()
  const isActive = exact ? pathname === to : pathname.startsWith(to)

  return (
    <Link ref={ref} className={cx(className, isActive && 'active')} to={to} {...rest} />
  )
})

NavLink.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  exact: PropTypes.bool,
  hideUnderline: PropTypes.bool,
  messageProps: PropTypes.shape({ id: PropTypes.string }),
  messageId: PropTypes.string,
  size: PropTypes.string,
  to: PropTypes.string.isRequired,
}

export default NavLink
