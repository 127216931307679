import React, { useState, useEffect, useRef } from 'react'
import { setCookie, hasCookie } from 'cookies-next'
import { CSSTransition } from 'react-transition-group'
import cx from 'classnames'
import { userAgent, useDialog } from '@theneatcompany/nui'

import { Card, Text, Link, Button } from '@/components/common'
import routes from '@/routes'
import CookieConsentDialog from '@/components/Dialogs/CookieConsentDialog'

import styles from './CookieConsent.module.scss'

const COOKIE = 'pm-cookie-consent'

export default function CookieConsent() {
  const [active, setActive] = useState(false)
  const [hidden, setHidden] = useState(true)
  const wrapperRef = useRef()
  const [openCookieConsentDialog] = useDialog(CookieConsentDialog)

  const handleDismiss = () => {
    setCookie(COOKIE, 'true', { maxAge: 60 * 60 * 24 * 365 }) // expires in 1 year
    setActive(false)
  }

  useEffect(() => {
    if (hasCookie(COOKIE)) return

    if (userAgent.isMobile) {
      openCookieConsentDialog({
        onClose: handleDismiss,
      })
    } else {
      setHidden(false)
      requestAnimationFrame(() => {
        setActive(true)
      })
    }
  }, [])

  return (
    <CSSTransition
      classNames={{
        enter: styles['wrapper-enter'],
        enterActive: styles['wrapper-enter-active'],
        enterDone: styles['wrapper-enter-done'],
      }}
      in={active}
      nodeRef={wrapperRef}
      onExited={() => setHidden(true)}
      timeout={300}
    >
      <div ref={wrapperRef} className={cx(styles.wrapper, hidden && styles.hidden)}>
        <Card className={styles.card} intent="neutral" withShadow>
          <Text.P
            center
            messageProps={{
              id: 'cookieConsent.p',
              values: {
                link: <Link messageId="cookieConsent.link" onClick={handleDismiss} size="xsmall" to={routes.privacy} />,
              },
            }}
            size="xsmall"
          />
          <Button messageId="cta.close" onClick={handleDismiss} variant="primary" />
        </Card>
      </div>
    </CSSTransition>
  )
}
