import React from 'react'

import { Rows } from '@/components/layout'
import { Text } from '@/components/common'
import MailingListOptInForm from '@/components/Pages/EmailPreferences/MailingListOptInForm'

import styles from './Footer.module.scss'

export default function FooterMailingList() {
  return (
    <Rows className="dark-background" gap={12}>
      <Text.H4 bold className={styles.listHeader} messageId="footer.mailingList.header" onTint size="small" type="body" />
      <Text.P messageId="footer.mailingList.label" onTint size="xsmall" />
      <MailingListOptInForm emailOnly />
    </Rows>
  )
}
