export const URLS = {
  appStore: 'https://apps.apple.com/us/app/neat-mobile/id1490480504?mt=8',
  certifiedThirdPartyScanners: 'https://support.neat.com/3-rdpartyscanners/certified-3-rd-party-scanners',
  playStore: 'https://play.google.com/store/apps/details?id=com.neat.mobile&pli=1',
  raven: 'https://www.raven.com/neat?utm_source=Neat&utm_medium=Website&utm_campaign=Certified_Scanners',
  social: {
    facebook: 'https://www.facebook.com/NeatCompany/',
    linkedin: 'https://www.linkedin.com/company/the-neat-company_2/',
    twitter: 'https://twitter.com/NeatCompany/',
  },
  support: {
    emailInFeature: 'https://support.neat.com/neatapp/email-in-feature-in-neat',
    home: 'https://support.neat.com/',
    supportTeam: 'https://support.neat.com/customerservice/contact-support-team',
    integrations: {
      campaignMonitor: 'https://support.neat.com/campaignmonitor/integrating-campaign-monitor-with-neat',
      constantContact: 'https://support.neat.com/constantcontact/integrating-constant-contact-with-neat',
      googleContacts: 'https://support.neat.com/googlecontacts/integrating-gmail-with-neat',
      hrblock: 'https://support.neat.com/smartorganizationsystem/export-to-h-r-block',
      mailchimp: 'https://support.neat.com/mailchimp/integrating-mail-chimp-with-neat',
      outlook: 'https://support.neat.com/outlook/integrating-outlook-with-neat',
      qbd: 'https://support.neat.com/qbd/integrating-quick-books-desktop-with-neat',
      qbo: 'https://support.neat.com/qbo/integrating-quick-books-online-with-neat',
      quicken: 'https://support.neat.com/smartorganizationsystem/export-to-quicken',
      turbotax: 'https://support.neat.com/smartorganizationsystem/export-to-turbo-tax',
    },
  },

}

export const DEFAULT_PLAN_SKU = 'neatyr'

export const TRIAL_SKU = 'neatyrt'
export const NEAT_HOME_TRIAL_SKU = 'neathomeyrt'

export const UPSELL_PLAN = 'neathomevipyr'
export const UPSELL_COUPON = 'HOMEVIPFALL24'
export const UPSELL_COUPON_DESCRIPTION = 'Free VIP Service'

export const MOBILE_BREAKPOINT = 768

// https://github.com/TheNeatCompany/StayPuft/blob/beaac1088cfbfad0f79a2e5ed8677939ef950e47/webapp/app/models/user_validations.rb#L4
export const EMAIL_REGEX = /^[a-z0-9*._+-]+@([a-z0-9._+-]+\.)+[a-z0-9._+-]+$/

export default {}
