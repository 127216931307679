/* eslint-disable react/require-default-props */
import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import { Link as NuiLink } from '@theneatcompany/nui'
import { FormattedMessage } from 'react-intl'
import cx from 'classnames'
import NextLink from 'next/link'

const Link = forwardRef(({
  children,
  className,
  messageProps = {},
  messageId = messageProps.id,
  variant,
  round = !!variant && variant !== 'default',
  bold = false,
  to,
  onClick,
  ...rest
}, ref) => (
  <NextLink href={to} {...rest} legacyBehavior>
    <NuiLink ref={ref} className={cx(className, bold && 'text-bold')} onClick={onClick} round={round} to={to} variant={variant} {...rest}>
      {messageId ? <FormattedMessage {...messageProps} id={messageId} /> : children}
    </NuiLink>
  </NextLink>
))

Link.propTypes = {
  bold: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  messageId: PropTypes.string,
  messageProps: PropTypes.shape({ id: PropTypes.string }),
  onClick: PropTypes.func,
  round: PropTypes.bool,
  to: PropTypes.string.isRequired,
  variant: PropTypes.string,
}

export default Link
